import { IHttpClient } from '@wix/yoshi-flow-editor';
import { getSeatingSummary } from '@wix/ambassador-seating-v1-seating-plan/http';

export const createApi = (httpClient: IHttpClient) => {
  return {
    getPlan: async (planId: string) => {
      if (!planId) {
        throw new Error('PlanId is mandatory');
      }

      const options = getSeatingSummary({ filter: { id: { $eq: planId } } });
      const response = await httpClient.request(options);

      return response.data.plan;
    },
  };
};
