import { SeatingPlan } from '@wix/ambassador-seating-v1-seating-plan/types';
import { PlacesStock } from '@wix/seating-common-components';
import { ControllerProps } from './Widget';
import { createApi } from './services/api';

const createController = async ({ flowAPI, controllerConfig }) => {
  const api = createApi(flowAPI.httpClient);

  let currentUnavailablePlaces: string[] = [];
  let currentSelectedPlaces: string[] = [];
  let currentPlan: SeatingPlan = null;

  return {
    async pageReady() {
      controllerConfig.setProps({
        fitToContentHeight: true,
      });
    },
    exports: () => {
      const setProps = (props: Partial<ControllerProps>) => {
        controllerConfig.setProps(props);
      };
      return {
        getPlan: async (planId: string) => api.getPlan(planId),
        set plan(plan: SeatingPlan) {
          currentPlan = plan;
          setProps({
            plan,
          });
        },
        get plan() {
          return currentPlan;
        },
        get selectedPlaces() {
          return currentSelectedPlaces;
        },
        set selectedPlaces(placesIds: string[]) {
          currentSelectedPlaces = placesIds;
          setProps({
            selectedSeatIds: placesIds,
          });
        },
        get unavailablePlaces() {
          return currentUnavailablePlaces;
        },
        set unavailablePlaces(places: string[]) {
          currentUnavailablePlaces = places;
          const placesStock: PlacesStock = places.reduce((acc, seatId) => {
            acc[seatId] = 0;
            return acc;
          }, {});
          setProps({ placesStock });
        },
        set onPlaceClick(callback: (places: string[]) => void) {
          setProps({
            onSeatClick: info => callback([info.place.id]),
          });
        },
      };
    },
  };
};

export default createController;
