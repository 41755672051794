
  import userController from '/home/builduser/work/2064253189211c70/packages/viewer/src/components/Widget/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://83bbe6dcc3ea4b44ac87b8ce1f0bc27c@sentry.wixpress.com/2348',
    id: '83bbe6dcc3ea4b44ac87b8ce1f0bc27c',
    projectName: 'viewer',
    teamName: 'events',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["viewer-apps-14ce28f7-7eb0-3745-22f8-074b0e2401fb"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/2064253189211c70/packages/viewer/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var biConfig = null;

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/2064253189211c70/packages/viewer/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Seating",
    componentName: "Widget",
    appDefinitionId: "64e6bcf3-b6e2-456a-81f9-b0cfbdd5c2c3",
    componentId: "2c546300-e889-4a8b-a968-ebf2cdc95b0a",
    projectName: "wix-seating-viewer",
    defaultTranslations,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/2064253189211c70/packages/viewer/src/components/Widget/controller.ts",
  };

  export const controller = _controller
  export default controller;
